import './bootstrap';

import { Alpine, Livewire } from '../../vendor/livewire/livewire/dist/livewire.esm';
import Clipboard from '@ryangjchandler/alpine-clipboard';

import Tooltip from '@ryangjchandler/alpine-tooltip';
import Choices from 'choices.js';

import ui from '@alpinejs/ui';

import flatpickr from 'flatpickr';
import { German } from 'flatpickr/dist/l10n/de';

import Trix from 'trix/dist/trix.esm.js';

import * as FilePond from 'filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import { Editor } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';

import SignaturePad from 'signature_pad';

import 'livewire-sortable';

flatpickr.localize(German);

window.setupEditor = function (content) {
    let editor;

    return {
        content: content,

        init(element) {
            editor = new Editor({
                element: element,
                extensions: [
                    StarterKit,
                    Link.configure({
                        openOnClick: false,
                    }),
                ],
                editorProps: {
                    attributes: {
                        class: 'border prose max-w-lg p-3',
                    },
                },
                content: this.content,
                onUpdate: ({ editor }) => {
                    this.content = editor.getHTML();
                },
            });
            this.$watch('content', (content) => {
                // If the new content matches TipTap's then we just skip.
                if (content === editor.getHTML()) return;
                editor.commands.setContent(content, false);
            });
        },
        toggleBold() {
            editor.chain().toggleBold().focus().run();
        },
        toggleItalic() {
            editor.chain().toggleItalic().focus().run();
        },
        toggleStrike() {
            editor.chain().focus().toggleStrike().run();
        },
        toggleBulletList() {
            editor.chain().focus().toggleBulletList().run();
        },
        toggleOrderedList() {
            editor.chain().focus().toggleOrderedList().run();
        },
        toggleLink() {
            editor.chain().focus().setLink({ target: url }).run();
        },
        async insertHtml() {
            const clipboardContents = await navigator.clipboard.read();
            console.log(clipboardContents);
            editor.chain().focus().clearContent().run();

            for (const item of clipboardContents) {
                if (item.types.includes('text/html')) {
                    const blob = await item.getType('text/html');
                    const blobText = await blob.text();
                    console.log(blobText);
                    editor.chain().focus().insertContent(blobText).run();
                }
                if (item.types.includes('text/plain')) {
                    const blob = await item.getType('text/plain');
                    const blobText = await blob.text();
                    console.log(blobText);
                    editor.chain().focus().insertContent(blobText).run();
                }
            }
        },
    };
};

window.FilePond = FilePond;
window.FilePondPluginImagePreview = FilePondPluginImagePreview;
window.FilePondPluginFileValidateSize = FilePondPluginFileValidateSize;
window.FilePondPluginFileValidateType = FilePondPluginFileValidateType;

window.SignaturePad = SignaturePad;

window.flatpickr = flatpickr;
window.Choices = Choices;
window.trix = Trix;

Alpine.plugin(Clipboard);
Alpine.plugin(Tooltip);
Alpine.plugin(ui);
Livewire.start();
